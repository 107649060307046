<pf-closable-dialog
  (closeClicked)="close(undefined)"
  [showCloseButton]="true"
  [title]="title"
  [showLabel]="false"
>
  <div class="flex align-items-stretch flex-column">
    <p *ngIf="labelHtml; else labelTemplate" class="text-xl text-center" [innerHTML]="labelHtml"></p>
    <ng-template #labelTemplate>
      <p class="text-xl text-center" [innerHTML]="label"></p>
    </ng-template>

    <pf-form-action-bar class="mt-5" styleClass="gap-5 justify-content-center">
      <pf-action-button
        (buttonClicked)="close(true)"
        [disabled]="disabledConfirm"
        styleClass="text-xl"
        [label]="confirmLabel ?? 'PF.GLOBAL.YES' | translate"
        type="custom"
      >
      </pf-action-button>
      @if (displayCancelButton) {
        <pf-action-button
          (buttonClicked)="close(false)"
          styleClass="text-xl p-button-tertiary"
          [label]="cancelLabel ?? 'PF.GLOBAL.NO' | translate"
        >
        </pf-action-button>
      }
    </pf-form-action-bar>
  </div>
</pf-closable-dialog>
