import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  PfActionButtonComponent,
  PfClosableDialogComponent,
  PfFormActionBarComponent,
} from 'pf-ui';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export type SpecificConfirmDialogData = {
  label?: string;
  labelHtml?: string,
  confirmLabel?: string;
  cancelLabel?: string;
  title?: string;
  disabledConfirm?: boolean,
  displayCancelButton?: boolean
};

@Component({
  selector: 'app-specific-confirm-dialog',
  templateUrl: './specific-confirm-dialog.component.html',
  styleUrl: './specific-confirm-dialog.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DialogModule,
    PfFormActionBarComponent,
    PfActionButtonComponent,
    PfClosableDialogComponent,
  ],
})
export class SpecificConfirmDialogComponent {
  public label: string = '';
  public labelHtml?: string = "";
  public title: string = '';
  public confirmLabel?: string = 'Confirmer';
  public cancelLabel?: string = 'Annuler';
  public displayCancelButton?: boolean = true;
  public disabledConfirm: boolean = false;

  constructor(
    private dialog: DynamicDialogRef,
    private config: DynamicDialogConfig<SpecificConfirmDialogData>
  ) {
    this.initValues(this.config.data);
  }

  initValues(data: SpecificConfirmDialogData | undefined): void {
    if (data === undefined) {
      return;
    }

    const {
      label, confirmLabel, cancelLabel, title,
      labelHtml, disabledConfirm, displayCancelButton
    } = data;

    if (labelHtml !== undefined) {
      this.labelHtml = labelHtml;
    }

    if (label !== undefined) {
      this.label = label;
    }

    if (confirmLabel !== undefined) {
      this.confirmLabel = confirmLabel;
    }

    if (cancelLabel !== undefined) {
      this.cancelLabel = cancelLabel;
    }

    if (title !== undefined) {
      this.title = title;
    }

    if (displayCancelButton !== undefined) {
      this.displayCancelButton = displayCancelButton;
    }

    this.disabledConfirm = disabledConfirm ?? false;
  }

  public close(save?: boolean): void {
    this.dialog.close(save);
  }
}
